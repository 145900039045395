import {
  Footer,
  Header,
  Hero,
  HomeBrands,
  HomeFaq,
  HomeFeatures,
  HomeJoin,
  HomeQuote,
} from "~/components";
import { GetServerSideProps, GetServerSidePropsContext, NextPage } from "next";
import { initializeApollo } from "~/server/apollo";
import {
  GetDoctorsAmountDocument,
  GetDoctorsAmountQuery,
  GetDoctorsAmountQueryVariables,
  GetMedicalSpecializationsDocument,
  GetMedicalSpecializationsQuery,
  GetMedicalSpecializationsQueryVariables,
  MedicalSpecializationFragment,
} from "~/types/graphql";
import { getSelectorsByUserAgent } from "react-device-detect";

interface HomeProps {
  doctorsAmount: number;
  medicalSpecializations: MedicalSpecializationFragment[];
  isMobile?: boolean;
}

const Home: NextPage<HomeProps> = ({
  doctorsAmount,
  medicalSpecializations,
  isMobile,
}) => {
  return (
    <main>
      <Header />
      <Hero
        doctorsAmount={doctorsAmount}
        medicalSpecializations={medicalSpecializations}
        isMobile={isMobile}
      />
      <HomeFeatures doctorsAmount={doctorsAmount} />
      <HomeQuote />
      <HomeBrands />
      <HomeJoin />
      <HomeFaq />
      <Footer />
    </main>
  );
};

export default Home;

export const getServerSideProps: GetServerSideProps<HomeProps> = async (
  ctx: GetServerSidePropsContext,
) => {
  const client = initializeApollo({ ctx });
  const userAgent = ctx.req.headers["user-agent"] ?? "";
  const { isMobile } = getSelectorsByUserAgent(userAgent);
  try {
    const { data } = await client.query<
      GetDoctorsAmountQuery,
      GetDoctorsAmountQueryVariables
    >({
      query: GetDoctorsAmountDocument,
    });
    const medicalSpecializations = await client.query<
      GetMedicalSpecializationsQuery,
      GetMedicalSpecializationsQueryVariables
    >({
      query: GetMedicalSpecializationsDocument,
      variables: {
        inCatalog: true,
      },
    });

    const sortedSpecializations =
      medicalSpecializations.data?.publicMedicalSpecializations
        ?.filter(
          specialization =>
            specialization !== null && specialization !== undefined,
        )
        ?.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        }) ?? [];

    return {
      props: {
        doctorsAmount: data.doctorsAmount || 0,
        medicalSpecializations:
          sortedSpecializations as MedicalSpecializationFragment[],
        isMobile,
      },
    };
  } catch (err) {
    console.error(err);
    return {
      props: {
        doctorsAmount: 0,
        medicalSpecializations: [],
        isMobile,
      },
    };
  }
};
